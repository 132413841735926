<template>
	<div>
		<f-default-header
			:title="$t('pages.settings.title')"
			:subtitle="$t('pages.settings.country.subtitle')" />

		<f-card>
			<v-row>
				<v-col cols="12">
					<f-card-title>{{ $t("labels.data") }}</f-card-title>
				</v-col>
			</v-row>
			<v-form v-model="isValid" ref="form">
				<v-row>
					<v-col cols="4">
						<v-text-field
							v-model="countries.name.en"
							outlined
							dense
							:label="$t('labels.countryEn')"
							:rules="[required]" />
					</v-col>
					<v-col cols="4">
						<v-text-field
							v-model="countries.name.es"
							outlined
							dense
							:label="$t('labels.countryEs')"
							:rules="[required]" />
					</v-col>
					<v-col cols="4">
						<v-text-field
							v-model="countries.name.pt"
							outlined
							dense
							:label="$t('labels.countryPt')"
							:rules="[required]" />
					</v-col>
				</v-row>
			</v-form>
		</f-card>

		<f-actions-btn-holder>
			<v-btn color="secondary" elevation="0" @click="back" large>
				{{ $t("labels.btnCancel") }}
			</v-btn>
			&nbsp;
			<v-btn color="primary" elevation="0" large @click="save">
				{{ $t("labels.btnSave") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import { SETTINGS_ROUTES } from "./../../../router/settings";
import validatorRules from "../../../helpers/validator_rules";
import * as country from "../../../services/country";

export default {
	data: () => ({
		countryID: null,
		isValid: false,
		flags: {
			creating: false,
		},
	}),
	created() {
		this.flags.creating = this.$route.name == SETTINGS_ROUTES.COUNTRY_CREATE;
		if (!this.flags.creating) {
			this.countryID = this.$route.params.id;
			country.get(this.countryID);
		}
	},
	methods: {
		async getData() {
			console.log("created");
		},
		async save() {
			this.$refs.form.validate();
			if (!this.isValid) return false;

			if (this.flags.creating) {
				await country.create();
				this.$store.commit("snackbar/show", {
					content: this.$t("snackbar.saveCountry"),
					type: "success",
				});
			} else {
				await country.edit();
				this.$store.commit("snackbar/show", {
					content: this.$t("snackbar.editCountry"),
					type: "success",
				});
			}
			this.back();
		},
		async remove() {
			var response = await this.$store.dispatch("dialog/show", {
				title: "Remover",
				content: "Você deseja remover esse cargo?",
			});
			if (response) {
				await this.$store.dispatch("jobRoles/remove");
				this.back();
			}
		},
		back() {
			this.$store.dispatch("country/clear", "view");
			this.$router.push({
				name: SETTINGS_ROUTES.COUNTRY_LIST,
			});
		},
	},
	computed: {
		required() {
			return validatorRules.required(this.$t("validation.required"));
		},
		countries() {
			return this.$store.state.country.view;
		},
	},
};
</script>
